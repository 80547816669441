<template>
  <div class="mainform">
    <div class="mainHeader">
      直发送货需求
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form mg-form">
      <el-form class="content" ref="form" :class="{disabled:formDisabled}" :disabled="formDisabled" :model="form" >
        <div class="elrowflx">
          <el-row :gutter="20">
            <el-col class="clearfix" :span="12">
              <div class="xjtit">送货需求单号</div>
              <div class="xjcont">{{demandCode}}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">需求下发时间</div>
              <div class="xjcont">{{qwsdTime.xfData}}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">送货地址</div>
              <div class="xjcont">{{dataBox.baseName}}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">发布人</div>
              <div class="xjcont">{{userName}}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">联系人</div>
              <div class="xjcont">{{dataBox.baseHead}} {{dataBox.phone}}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">状态</div>
              <div class="xjcont">{{dataBox.status === 1 ? '待我确认' : dataBox.status === 2 ? '待供应商确认' : dataBox.status === 3 ? '供应商已确认' : dataBox.status === 4 ? '已签收' : ''}}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">期望送达日期</div>
              <div class="xjcont">
                <el-form-item label-width="0">
                  <el-date-picker v-model="qwsdTime.sdData" type="date" placeholder="请选择"></el-date-picker>
                </el-form-item>
              </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">是否需要SN</div>
              <div class="xjcont">
                <el-radio-group v-model="form.sn">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </div>
            </el-col>
            <el-col class="clearfix cel-mgtop" v-if="showTextarea || form.remark" :span="24">
              <div class="xjtit">备注</div>
              <div class="xjcont wi-xjcont">
                <el-input type="textarea" v-model="form.remark" placeholder="请输入备注信息"></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="excel-tab">
          <el-table size=mini :data="form.bidScoringList" stripe style="width: 100%">
            <el-table-column prop="demandCode" label="物料编号" :show-overflow-tooltip="true" width="140px"></el-table-column>
            <el-table-column prop="materialName" label="物料描述" :show-overflow-tooltip="true" width=""></el-table-column>
            <el-table-column prop="orderCode" label="采购单编号" :show-overflow-tooltip="true" width="140px"></el-table-column>
            <el-table-column prop="orderRow" label="采购单行号" :show-overflow-tooltip="true" width="110px"></el-table-column>
            <el-table-column prop="demandNumber" label="需求数量" :show-overflow-tooltip="true" width="110px"></el-table-column>
            <el-table-column prop="dw" label="单位" :show-overflow-tooltip="true" width="100px"></el-table-column>
            <el-table-column prop="factory" label="工厂" :show-overflow-tooltip="true" width="100px"></el-table-column>
          </el-table>
        </div>
      </el-form>
      <div class="qsdClass" v-if="dataBox.status === 4">
        <span>签收单</span>
        <el-button type="text" @click="disDownFlie">{{form.bidScoringList[0].signFileName}}</el-button>
      </div>
      <div class="action">
        <div v-if="dataBox.status == 1 || dataBox.status == 2">
          <el-button class="submit" type="primary" size="medium" @click="confirm">确认</el-button>
          <el-button v-if="edisType === 1" class="submit" type="primary" size="medium" @click="edist">编辑</el-button>
        </div>
        <!-- <div v-if="dataBox.status == 2">
          <el-button class="submit" type="primary" size="medium" @click="confirm">确认</el-button>
          <el-button class="submit" type="primary" size="medium" @click="edist">编辑</el-button>
        </div> -->
        <div v-if="dataBox.status == 3">
          <el-button v-if="edisType === 2" class="submit" type="primary" size="medium" @click="confirm">确认</el-button>
          <el-button v-if="edisType === 1" class="submit" type="primary" size="medium" @click="edist">编辑</el-button>
          <el-button v-if="edisType === 1" class="submit" type="primary" size="medium" @click="upData">上传签收单</el-button>
        </div>
        <!-- <div v-if="dataBox.status == 4">
          <el-button class="submit" type="primary" size="medium" @click="downBylob(file)">下载签收单</el-button>
        </div> -->
      </div>
      <!-- 是否需要SN-修改弹框 -->
      <div class="dialogBox">
        <el-dialog top="0px" title="是否需要SN" v-model="dialogModify" width="30%">
          <div class="content">
            <div class="mainform">
              <div class="form">
                <el-form :model="form" ref="form" label-width="110px" class="demo-ruleForm">
                  <el-form-item style="margin-bottom: 0;" label="是否需要SN" prop="sn">
                    <el-radio-group v-model="form.sn">
                      <el-radio label="1">是</el-radio>
                      <el-radio label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <template #footer>
            <div class="dialog-footer">
              <el-button size="mini" @click="dialogModify = false">取 消</el-button>
              <el-button size="mini" type="primary" @click="confirmModify">确 定</el-button>
            </div>
          </template>
        </el-dialog>
      </div>

      <!-- 上传签收单弹框 -->
      <div class="dialogBox">
        <el-dialog top="0px" title="上传签收单" v-model="dialogModify1" width="40%">
          <div class="content">
            <div class="mainform">
              <div class="form">
                <el-form :model="endTimeData" ref="endTimeData" label-width="110px" class="demo-ruleForm">
                  <el-form-item label="签收日期" prop="endTime">
                    <el-date-picker type="datetime" @change="timeForm" placeholder="选择日期" v-model="endTimeData.endTime" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="签收单" prop="sn">
                    <el-upload
                      ref="upload"
                      name="file"
                      :auto-upload="true"
                      :action= 'postUrl'
                      :limit="1"
                      :on-preview="downBylob"
                      :on-exceed="handleExceed"
                      :on-remove="handleRemoveQsd"
                      :on-success="handSuccess"
                      :on-error="handError"
                      :headers="tokenHeader"
                      :file-list="upReturnData"
                    >
                      <el-button size="mini" type="primary" plain>+上传</el-button>
                    </el-upload>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <template #footer>
            <div class="dialog-footer">
              <el-button size="mini" @click="dialogModify1 = false">取 消</el-button>
              <el-button size="mini" type="primary" @click="edisUpData">确 定</el-button>
            </div>
          </template>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { fileHandle } from '@/assets/js/FileAction.js'
import { baseURL, request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'HairPurchaserDetails',
  data: function () {
    return {
      userName: '',
      endTimeData: {
        endTime: ''
      },
      qwsdTime: {
        sdData: '',
        xfData: ''
      },
      upReturnData: [],
      edisType: 1,
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      postUrl: baseURL + '/api/file/m/file/uploadToServer',
      showTextarea: false,
      demandCode: this.$route.query.demandCode,
      formDisabled: false,
      dialogShow: false,
      dialogModify: false,
      dialogModify1: false,
      menudata: this.$store.state.ViewMenuData.buttonData,
      dataBox: {},
      form: {
        remark: '',
        sn: null,
        shTime: '',
        businessScope: '',
        bidScoringList: []
      }
    }
  },
  mounted () {
    if (this.demandCode) {
      this.formDisabled = true
    }
  },
  created () {
    this.getIdData()
  },
  methods: {
    // 根据需求单号请求数据
    getIdData () {
      const demandCode = this.demandCode
      if (demandCode) {
        request('/api/livraison/direct/getLivraisonDirectDetailDataListByDemandCode?' + 'demandCode=' + demandCode, 'POST').then((res) => {
          if (res.code === '200') {
            this.form.bidScoringList = res.data.directDemandData
            this.userName = this.form.bidScoringList[0].userName
            this.form.sn = JSON.stringify(this.form.bidScoringList[0].snFlag)
            this.form.remark = this.form.bidScoringList[0].remark
            this.dataBox = res.data.baseData
            this.dataBox.status = this.form.bidScoringList[0].status
            this.qwsdTime.sdData = this.form.bidScoringList[0].demandTime
            this.qwsdTime.xfData = this.form.bidScoringList[0].issueTime
            this.form.xjDescribe = res.data.ruleComment
            this.form.checkBusinessScope = res.data.ruleSupplier
            this.form.creationDate = res.data.creationDate
            this.form.createUsername = res.data.createUsername
            this.form.ruleType = res.data.ruleType
            this.form.proofType = res.data.proofType
          }
        })
      }
    },

    // 点击详情页编辑按钮
    edist () {
      this.edisType = 2
      this.formDisabled = false
      this.showTextarea = true
    },
    // 确认
    confirm () {
      this.edisType = 1
      this.id = this.$route.query.id
      this.formDisabled = true
      if (this.form.sn === 'null') {
        this.dialogModify = true
      } else {
        var qwSdTime = ''
        if (this.qwsdTime.sdData) {
          qwSdTime = dayjs(this.qwsdTime.sdData).format('YYYY-MM-DD')
        }
        const obj = {
          demandCode: this.demandCode,
          demandData: qwSdTime,
          remark: this.form.remark,
          snFlag: this.form.sn
        }
        request('/api/livraison/direct/updateLivraisonDirectDetailDataListByDemandCodeOfBuyer', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.dialogModify = false
            this.getIdData()
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
          }
        })
      }
    },
    // 发布
    deliver () {

    },
    // 上传签收单
    upData () {
      this.dialogModify1 = true
    },
    // 点击上传签收单里的确认按钮
    edisUpData () {
      if (!this.endTimeData.endTime) {
        this.$message({
          showClose: true,
          message: '请选择签收日期',
          type: 'warning'
        })
      } else if (this.upReturnData.length === 0) {
        this.$message({
          showClose: true,
          message: '请上传签收单',
          type: 'warning'
        })
      } else {
        const obj = {
          files: this.upReturnData,
          signTime: this.endTimeData.endTime,
          demandCode: this.demandCode
        }
        request('/api/livraison/direct/uploadFileOfSign', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.dialogModify = false
            this.getIdData()
          }
        })
      }
    },
    // 下载签收单
    downBylob (file) {
      fileHandle.handlePreview(file)
    },
    // 上传成功后下载签收单
    disDownFlie () {
      var newSignFilerID = this.form.bidScoringList[0].signFileId
      var newSignFilerName = this.form.bidScoringList[0].signFileName
      this.downBylob({ id: newSignFilerID, name: newSignFilerName })
    },
    // 点击是否需要SN-修改弹框里的确认按钮
    confirmModify () {
      if (this.form.sn) {
        var qwSdTime = ''
        if (this.qwsdTime.sdData) {
          qwSdTime = dayjs(this.qwsdTime.sdData).format('YYYY-MM-DD')
        }
        const obj = {
          snFlag: this.form.sn,
          demandCode: this.demandCode,
          remark: this.form.remark,
          demandData: qwSdTime
        }
        request('/api/livraison/direct/updateLivraisonDirectDetailDataListByDemandCodeOfBuyer', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.dialogModify = false
            this.getIdData()
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: '请选择是否需要SN',
          type: 'warning'
        })
      }
    },
    // 判断是否只能上传一个文件，超过提示报错
    handleExceed (e) {
      this.$message({
        showClose: true,
        message: '只能上传一个文件',
        type: 'warning'
      })
    },

    // 上传失败提示
    handError () {
      this.$message({
        showClose: true,
        message: '文件上传失败',
        type: 'error'
      })
    },
    // 上传成功或错误提示
    handSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(item => {
          this.upReturnData.push(item)
        })
      }
    },
    // 移除上传签收单文件
    handleRemoveQsd (file) {
      this.upReturnData = []
    },
    // 选择签收日期转换格式
    timeForm (val) {
      if (this.endTimeData.endTime) {
        this.endTimeData.endTime = dayjs(this.endTimeData.endTime).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.endTimeData.endTime = ''
      }
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  @import '@/assets/css/dialogBox.scss';
  .xj-code{
    margin-bottom: 20px;
    color: #606266;
  }
  .xj-span1{
    display: inline-block;
    width: 158px;
    text-align: right;
    padding-right: 12px;
    font-size: 14px;
  }
  .xj-span2{
    font-size: 14px;
  }
  .excel-tab{
    margin-bottom: 20px;
  }
  .mg-form{
    margin-top: 20px;
    :deep(.el-table th){
      padding: 9px 0;
      background: #ededed;
      font-size: 14px;
    }
    :deep(.el-table td){
      padding: 9px 0;
      font-size: 14px;
    }
    :deep(.el-input__inner){
      height: 30px !important;
      line-height: 30px !important;
    }
    .elrowflx{
      margin-bottom: 20px;
      :deep(.el-col){
        // margin-bottom: 20px
        line-height: 34px;
      }
    }
    .xjtit{
      float: left;
      margin-right: 20px;
      min-width: 90px;
      text-align: right;
      color: #666;
      font-size: 14px;
    }
    .xjcont{
      float: left;
      width: 50%;
      color: #666;
      font-size: 14px;
      .el-form-item{
        margin-bottom: 0;
      }
    }
    .wi-xjcont{
      width: calc(100% - 110px);
    }
    .disabled{
      // :deep(.el-table td){
      //   padding: 9px 0;
      // }
      :deep(.el-input__prefix){
        display: none;
      }
      :deep(.el-input__inner){
        padding: 0;
      }
    }
    .cel-mgtop{
      margin-top: 15px;
    }
    .disabled{
      :deep(.el-textarea__inner) {
        background: none;
        border: none;
        font-size: 14px;
        padding: 7px 0;
        color: #666;
        font-family: "Microsoft YaHei";
      }
      .cel-mgtop{
        margin-top: 0;
      }
      :deep(.el-input__inner){
        color: #666;
        font-size: 14px;
        background: none;
        border: 0;
      }
    }
  }
  .qsdClass{
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .qsdClass span{
    padding-right: 15px;
  }
</style>
